import React,{useState} from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import {VscError} from "react-icons/vsc"
import "../App.css";
// import Form from 'react-bootstrap/FormGroup';


const FormDetails = ({ToggleClose}) => {
    const [formData, updateFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
      });
      const handleChange = (e) => {
        const { name, value } = e.target;
        updateFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      };

  return (
    <>
    

      <Form className='formDetails'>
      <div className="icon" >
    <VscError onClick={ToggleClose}/>
  </div>
    <Form.Group controlId="name">
      <Form.Label>Name</Form.Label>
      <Form.Control
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
      />
    </Form.Group>

    <Form.Group controlId="email">
      <Form.Label>Email</Form.Label>
      <Form.Control
        type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        />
    </Form.Group>

    <Form.Group controlId="phoneNumber">
      <Form.Label>Phone Number</Form.Label>
      <Form.Control
        type="tel"
        name="phoneNumber"
        value={formData.phoneNumber}
        onChange={handleChange}
        />
    </Form.Group>
    <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        Services
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1">FACILITY MANAGEMENT</Dropdown.Item>
        <Dropdown.Item href="#/action-2">MAPOWER OUTSOURCING</Dropdown.Item>
        <Dropdown.Item href="#/action-3">MACHANISED HOUSEKEEPING</Dropdown.Item>
        <Dropdown.Item href="#/action-2">SECURITY MANAGEMENT</Dropdown.Item>
        <Dropdown.Item href="#/action-2">CIVIL CONSTRUCTION WORKS</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>



    <Button type="submit">Submit</Button>
    {/* <div className="icon">
    <VscError/>
  </div> */}
  </Form>
  {/* <div className="icon">
    <VscError/>
  </div> */}
        
    
    </>
  )
}

export default FormDetails