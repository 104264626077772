import React ,{useState}  from 'react';
import Button from 'react-bootstrap/Button';
import {Image} from 'react-bootstrap';
import FormDetails from '../Components/FormDetails';
import "../App.css"
const Home = () => {
  const [open,SetOpen] =useState(false);
   const  Toggle =()=>{
          SetOpen(true)
   }
   const ToggleClose =()=>{
    SetOpen(false)
   }
  return (
    <div className='home d-flex justify-content-center align-items-center flex-wrap'>
        <div className="LeftSide ">
            <h1 className='fw-bold'>
                Welcome
            To
            <br/>
            KRISHNA ENTERPRISES</h1>
            
            <Button variant="success" className='fw-semibold fs-5' onClick={Toggle}>
            Book An Consultant</Button>
                
            
        </div>
        <div className="RightSide">
            <span>

            <Image src='https://www.csginc.com/wp-content/uploads/2022/05/facility-managers-cut-costs-without-quality-1240x698-1.jpg'/>
            </span>
        </div>
        {
         open && 
          <FormDetails ToggleClose={ToggleClose} />
        }
      
    </div>
  )
}

export default Home