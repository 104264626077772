import React  from 'react'
import {Link} from "react-router-dom"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Image} from 'react-bootstrap';
import logo from "../Components/logos2.png"
// import logo from '../Components/logo.jpg';
import "../App.css";

const MyNavbar = () => {
  return (
    <div>
  <Navbar expand="lg" className="navbar text-secondary  " >
      <Container className='container'>

        <Link to='/'>
        <Navbar.Brand >
        <Image  className='logo rounded-2' src={logo} alt ="logo">
        </Image>

        </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto gap-3">
            <Link to="/">
            <Nav.Link href="#home" className=' nav-item fs-4 '>Home</Nav.Link>
            </Link>
            <Link to="/services">
            <Nav.Link  href='services' className=' nav-item fs-4' >Services</Nav.Link>
            </Link>
            <Link to="/about">
            <Nav.Link href="/about" className='fs-4 nav-item'>About Us</Nav.Link>
            </Link>
            <Link to="/contact">
            <Nav.Link href="/contact" className='fs-4 nav-item'>Contact Us</Nav.Link>
            </Link>
            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

    </div>
  )
}

export default MyNavbar