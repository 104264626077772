import React from 'react'
import {Image} from 'react-bootstrap';
import "../App.css"
const About = () => {
  return (
    <>
    <div className="AboutPage">
      <div className="aboutHeading d-flex justify-content-center align-items-center flex-wrap">
<div className="headingSecond">

    <h1>About Us</h1>
    <p>
    Krishna Enterprises is constituted under the laws of Republic of India & established due to the increasing demand in the manpower solutions.

    </p>
    <p>
    Krishna Enterprises came into existence in the year 2020. It is specialized to cater various organizations to meet their Outsourced Manpower needs of Highly- skilled, skilled, semi-skilled and un-skilled categories as well as construction works.
    </p>
    <p>
    Our USP is to work with the leaders in this field.
    </p>
</div>
    <div className="img">
        <Image src="https://img.freepik.com/free-photo/amazing-cheerful-business-woman-standing-with-arms-crossed_171337-8487.jpg"/>
    </div>
      </div>
    
    {/* <h1>Vision & Mission</h1>
    <span>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum atque ipsam autem laudantium tempora modi perferendis, qui ab veritatis libero quisquam quo excepturi aut distinctio ducimus temporibus debitis voluptas nulla vitae. Consectetur quibusdam sequi ad fuga, maiores sapiente earum debitis!
    </span>
    <h1>Key Objectives</h1>
    <span>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem vel inventore, adipisci maiores cumque tempore voluptatum doloremque iste quas ipsum. Error quo animi blanditiis, quos tempora nihil voluptas, cum deleniti doloremque dolorum soluta vero?
    </span> */}
    
    </div>
    </>
  )
}

export default About