import Accordion from 'react-bootstrap/Accordion';
import React from 'react'
import "../App.css"
import {Image} from 'react-bootstrap';
import facility from "./Asset/FACILITY MANAGEMENT.jpg"
import Machanized from "./Asset/MACHANIZED.jpg"
import Security from "./Asset/SECURITY MANAGEMENT.jpg"
import Civil from "./Asset/CIVIL CONSTRUCTION WORKS.jpg"
import Man from "./Asset/MANPOWER.jpg"
// import {LiaStarSolid} from "react-icons/lia"
const Services = () => {
  return (
    <>
    <div className="services">
    <h1>Our Services</h1>
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header className='fs-2 fw-bold'>FACILITY MANAGEMENT</Accordion.Header>
      
        <Accordion.Body>
         
         <div className="faciltiyManagement d-flex justify-content-center align-items-center flex-wrap">
          <div className="content">
          <p className='fs-5 fw-medium'>
        {/* <LiaStarSolid/> */}
          Facility Management is professional management discipline focused upon the management discipline focused upon the efficient and effective delivery of support services for the organizations that it serves.
          </p>
          <p className='fs-5 fw-medium' >
            It serves to ensure the integration of people system,place,process,and technology.
          </p>
          <p className='fs-5 fw-medium' >
            Facility Management services are designed and delivered according to the customers need.
          </p>
          </div>
          <div className="content-img">
            <Image src={facility}/>
          </div>
         </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>MANPOWER OUTSOURCING</Accordion.Header>
        <Accordion.Body>
          <div className="manpower d-flex justify-content-center align-items-center flex-wrap">
        <div className="content">

         <p className='fs-5 fw-medium'>
          Computer Professionals/Computer Operators/Telepone operators
         </p>
         <p className='fs-5 fw-medium'>
          Front Office Executive/Receptionist/Personal Assistant/Office Secretaries
         </p>
         <p className='fs-5 fw-medium'>
        Administrative & HR Personnel
         </p>
     <p className='fs-5 fw-medium' >
      Supervisorss/Office Assistant/Typist/Stenographers/Clerks/Office Boys/Cashiers/Collection & Recover Staff/Pantry Boys/Sweepers/Dak Runners /Drivers/gardeners/Servants& Maids etc.
     </p>
     <p className='fs-5 fw-medium'>
      Agricultural / Horticultural labours etc.
     </p>
        </div>
        <div className="img">
          <Image src={Man}/>
        </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>MACHANISED HOUSEKEEPING</Accordion.Header>
        <Accordion.Body>
          <div className="machansied d-flex justify-content-center align-items-center flex-wrap">
            <div className="content">

            <p className='fs-5 fw-medium' >
              Mechanized cleaning and hygiene maintenance Services
            </p>
            <p className='fs-5 fw-medium' >
              Conservancy and Sanitation Services
            </p>
            <p className='fs-5 fw-medium' >
              Janitorial services
            </p>
            <p className='fs-5 fw-medium' >
              Commerical Building Housekeeping Services
            </p>
            <p className='fs-5 fw-medium' >
              Office HouseKeeping Services
            </p>
            <p className='fs-5 fw-medium'>
              Sanitization Services in Hospitals/Medical Institutions
            </p>
            <p className='fs-5 fw-medium' >HVAC management Services and AMC</p>
            </div>
            <div className="img">
              <Image src={Machanized}/>
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3">
        <Accordion.Header>SECURITY MANAGEMENT</Accordion.Header>
        <Accordion.Body>
         <div className="security d-flex justify-content-center align-items-center flex-wrap">
          <div className="content">
            <p className='fs-5 fw-medium' >
            Understand your client's needs. What are their biggest security concerns? What assets do they need to protect? What are their compliance requirements? Once you have a good understanding of their needs, you can tailor your services accordingly.
            </p>
            <p className='fs-5 fw-medium' >
            Offer a comprehensive range of services. Security management is a complex field, so it's important to offer a wide range of services to meet the needs of your clients. This may include risk assessment, incident response, vulnerability management, and compliance consulting.
            </p>
            <p className='fs-5 fw-medium'>
            Provide expert advice and support. Your clients should be able to rely on you for expert advice and support on all aspects of security management. You should be able to help them develop and implement security policies and procedures, and respond to incidents quickly and effectively.
            </p>
          </div>
          <div className="img">
           <Image src={Security} alt="security" />
          </div>
         </div>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="4">
        <Accordion.Header>CIVIL CONSTRUCTION WORKS</Accordion.Header>
        <Accordion.Body>
     <div className="civil d-flex justify-content-center align-items-center flex-wrap">
      <div className="content">
        <p className='fs-5 fw-medium' >
        Understand the client's needs and expectations. This is essential for providing a service that meets the client's requirements and satisfaction.
        </p>
        <p className='fs-5 fw-medium'>
        Be clear about the scope of work. This includes defining the specific tasks to be completed, the materials and equipment to be used, and the timeline for the project.
        </p>
        <p className='fs-5 fw-medium'>
        Provide a competitive quote. The quote should be based on a realistic assessment of the costs involved in completing the project.
        </p>
      </div>
      <div className="img">
        <Image src={Civil} alt='civil' />
      </div>
     </div>
        </Accordion.Body>
      </Accordion.Item>

    </Accordion>


    </div>
    </>
  )
}

export default Services



