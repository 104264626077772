import React from 'react';
import {FaMapMarkerAlt} from "react-icons/fa";
import {BiPhoneCall} from "react-icons/bi";
import {GrMail} from "react-icons/gr"
import {BsWhatsapp} from "react-icons/bs";
// import {Image} from 'react-bootstrap';
// import contact from "./contactlogo.png"
// import {BsInstagram} from "react-icons/bs"
// import {AiFillFacebook} from "react-icons/ai"


// import {FaWhatsappSquare} from "react-icons/fa"

const Contact = () => {
  return (
<>
<div className="contactHeading">
<h1 className='fs-2 text-center mt-4np'>Contact Us</h1>
{/* <div className="contactImg">
    {/* <Image src={contact}/> */}
{/* </div>  */}
</div>

<div className="contact">

<footer className='d-flex justify-content-center align-items-center mt-4 gap-3'>
    <div className="address">
    <p className='fs-5'>
        <span>
    <FaMapMarkerAlt/> 
        </span>
        Headquarters -M/s KRISHNA ENTERPRISES,<br/>
        
        Surabhi Vihar,Near Shara Sandhya Hospital, <br/>
        
        PO-Dhelwan,P/s Beur,Patna-800020(Bihar)
        
    </p>
    <p className='fs-5'>
        <a href='/' className='text-decoration-none'>

    <BiPhoneCall/>
    <span className='text-black'>
        
         +91 7483906825
        </span>
        </a>
    </p>

    <p className='fs-5'>
        <a href='https://wa.me/917483906825' target="_blank" rel="noopener noreferrer" className='text-decoration-none'>
        

    <BsWhatsapp/> <span className='text-black'>
        
        +91 7483906825
        </span> 
        </a>
    </p>
    
    <span className='fs-5'>
       <a href="mailto:suraj.947345@gmail.com" target='_blank' rel="noopener noreferrer" className='text-decoration-none'>

    <GrMail/> <span className='text-black'>
        
        suraj.947345@gmail.com
        </span> 
       </a>
    </span>
    </div>
    <div className="socialMedia d-flex justify-content-center align-items-center">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3598.688544703921!2d85.13058337495472!3d25.582026816007097!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f2a789e2f3d561%3A0x948f0b41f014fb51!2sSahara%20Sandhya%20Hospital!5e0!3m2!1sen!2sin!4v1697113827036!5m2!1sen!2sin" width="600" height="450" style={{border:0}}  loading="lazy"  title='map'> </iframe>
        {/* <div className="insta"> */}
        {/* <span className='insta'>

            <BsInstagram   />
        </span>
            <AiFillFacebook />
            <FaWhatsappSquare  />
        </div> */}
    </div>

</footer>

</div>
</>

  )
}

export default Contact